<template>
  <component :is="isCurrentComponent"></component>
</template>

<script>
import VoiceBot from '@/components/services/voice_bot/VoiceBot.vue';
import BotDescription from '@/components/services/voice_bot/BotDescription.vue';
import { mapState } from 'vuex';

export default {
  components: {
    VoiceBot,
    BotDescription
  },

  computed: {
    ...mapState({ services: (state) => state.connectedServices.module.services }),

    isCurrentComponent() {
      return this.services.OnloDialog ? 'VoiceBot' : 'BotDescription';
    }
  }
};
</script>

<style></style>
