<template>
  <the-wrapper>
    <slot>
      <ServicesDescription @connectService="connectService">
        <template #title>
          {{ title }}
        </template>
        <template #body>
          <p v-for="(row, index) in listOfTextDescription" :key="index">
            {{ row }}
          </p>
          <div>
            <b-row v-for="item in userData" :key="item.label">
              <b-col sm="6">
                <b-form-group :label="item.label">
                  <component
                    :is="item.component"
                    v-model="item.data"
                    v-if="item.id === 'phone'"
                    :label="item.label"
                    :placeholder="item.placeholder"
                  ></component>
                  <component
                    :is="item.component"
                    v-model="item.data"
                    v-else
                    :label="item.label"
                    :placeholder="item.placeholder"
                  ></component>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
      </ServicesDescription>
    </slot>
  </the-wrapper>
</template>

<script>
import TheWrapper from '@/components/shared/TheWrapper.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import BaseFileInput from '@/components/shared/inputs/BaseFileInput.vue';
import BaseTextarea from '@/components/shared/inputs/BaseTextarea.vue';
import InputPhone from '@/components/shared/inputs/InputPhone.vue';
import { catchFunction, processResponse } from '@/additional_helpers/helpers';
import URL from '@/basUrl';
import { sendCustomerRequestWithFile } from '@/api/common';

export default {
  data() {
    return {
      areaRows: 5,
      title: 'Голосовой бот',
      userData: [
        {
          id: 'name',
          label: 'Имя:',
          data: '',
          component: 'BaseTextInput',
          placeholder: 'Введите имя'
        },
        {
          id: 'phone',
          label: 'Телефон:',
          data: '',
          component: 'InputPhone',
          placeholder: '+7 (xxx) xxx-xx-xx'
        },
        {
          id: 'file-field',
          label: 'Приложите файл с записью примера разговора:',
          data: null,
          component: 'base-file-input',
          placeholder: 'Выберите файл или перетащите в поле...'
        },
        {
          id: 'add-info',
          label: 'Дополнительная информация:',
          data: '',
          component: 'BaseTextarea',
          placeholder: 'Опишите требуемый функционал'
        }
      ],
      listOfTextDescription: [
        'В этом разделе будет размещен интерфейс настройки Голосового бота (сценарии звонков, настройки голоса, интонации, скорости речи и др.).',
        'Создания диалогов - сложная задача. Для этого нужен скриптолог, и он есть у нас!',
        'Раздел находится в разработке. В ближайшее время Вы самостоятельно сможете настраивать различные сценарии звонков с помощью этого раздела.',
        'А пока мы поможем создать Вашего персонального робота и обучить его всему необходимому.',
        'Пожалуйста, укажите контактную информацию для связи по вопросам настройки Голосового бота и опишите коротко требуемый функционал.'
      ]
    };
  },

  components: {
    TheWrapper,
    ServicesDescription,
    BaseTextInput,
    BaseTextarea,
    BaseFileInput,
    InputPhone
  },

  computed: {
    isValidForm() {
      const [name, phone] = this.userData;
      return name > 2 && phone.length === 18;
    }
  },

  methods: {
    checkInput(field) {
      return field === 'Имя:';
    },

    createModelToSend() {
      const formData = new FormData();
      const [name, phone, file, description] = this.userData;
      const row = JSON.stringify({
        FullName: name.data,
        PhoneNumber: phone.data,
        ClaimDescription: description.data
      });

      formData.append('json', row);
      formData.append('file', file.data);

      return formData;
    },

    connectService() {
      if (this.isValidForm) {
        sendCustomerRequestWithFile(URL.VOICE_BOT, this.createModelToSend())
          .then((res) => processResponse(res, 'Данные успешно отправлены'))
          .catch((e) => catchFunction(e));
      } else {
        catchFunction('Пожалуйста, заполните имя и номер телефона');
      }
    }
  }
};
</script>

<style></style>
