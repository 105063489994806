export const title = 'Голосовой бот';

export const description = `Голосовой бот автоматизирует обработку звонков и возьмёт на себя типовые задачи: примет заявку,
                            ответит на часто задаваемые вопросы, позвонит клиентам с напоминанием и соберёт отзывы`;

export const accomplishments = [{
  id: 1,
  title: 'Какие задачи поможет решить голосовой бот:',
  body: [{
    id: 'd_1',
    title: 'Примет заказ и проконсультирует',
    text: 'Расскажет о скидках, акциях или бонусах одновременно всем клиентам или выбранной категории и по расписанию'
  },
  {
    id: 'd_2',
    title: 'Совершит продажи и холодные звонки',
    text: 'Найдет теплых клиентов, совершая до 100 звонков в минуту, пока вы заняты другими делами'
  },
  {
    id: 'd_3',
    title: 'Проведет опрос',
    text: 'Проведет опрос и сформирует удобную подробную аналитику вместо секретаря или call-центра'
  }
  ]
},
{
  id: 2,
  title: 'Голосовой бот – выгодное решение для бизнеса:',
  body: [{
    id: 'al_1',
    title: 'Работа 24/7',
    text: 'Никогда не устаёт и всегда готов к общению'
  },
  {
    id: 'al_2',
    title: 'Одновременная обработка до 100 звонков',
    text: 'Исходящие и входящие - все звонки будут обработаны'
  },
  {
    id: 'al_3',
    title: 'Любые сценарии диалогов',
    text: 'Создайте свой сценарий диалога в зависимости от потребностей вашей компании'
  },
  {
    id: 'al_4',
    title: 'Синтез речи',
    text: 'Диалог робота выходит живым, нелинейным, робот отвечает без задержек. Робот воспроизводит текст в голосовое сообщение в соответствии с настройками голоса, интонации и скорости речи'
  },
  {
    id: 'al_5',
    title: 'Естественное общение',
    text: 'Система робота может перевести клиента на сотрудника, перезвонить позже в назначенное время, если клиенту не удобно разговаривать'
  },
  {
    id: 'al_6',
    title: 'Интеграция с CRM',
    text: 'По окончании звонка результат записывается в карточку клиента'
  },
  {
    id: 'al_7',
    title: 'Запись звонков и статистика',
    text: 'Система ведет полную статистику звонков, журналы разговоров и результаты звонков'
  }
  ]
}
];
