<template>
  <the-wrapper>
    <slot>
      <ServicesDescription
        :colsNine="colsNine"
        :isSubtitle="isSubtitle"
        @connectService="connectService"
      >
        <template #title>
          {{ title }}
        </template>
        <template #subtitle>
          {{ description }}
        </template>
        <template #body>
          <section v-for="section in accomplishments" :key="section.id">
            <h5>{{ section.title }}</h5>
            <br />
            <text-list :text="section.body" :isTitleAndText="isTitleAndText"></text-list>
            <br />
          </section>
        </template>
      </ServicesDescription>
    </slot>
  </the-wrapper>
</template>

<script>
import TheWrapper from '@/components/shared/TheWrapper.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import TextList from '@/components/shared/TextList.vue';
import {
  accomplishments,
  description,
  title
} from '@/components/services/voice_bot/voiceBotDescription';

export default {
  data() {
    return {
      isTitleAndText: true,
      colsNine: true,
      isSubtitle: true,
      accomplishments,
      description,
      title
    };
  },

  components: {
    TheWrapper,
    ServicesDescription,
    TextList
  },

  methods: {
    connectService() {
      this.$router.push({
        path: '/create-statement',
        query: { st: 'addService', service: 'голосовой-бот' }
      });
    }
  }
};
</script>

<style></style>
