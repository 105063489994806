<template>
  <b-form-file
    v-model="newFile"
    :id="id"
    :type="type"
    :placeholder="placeholder"
    browse-text="Выберите файл"
    class="input_color"
  ></b-form-file>
</template>

<script>
export default {
  model: {
    prop: 'file',
    event: 'setFile'
  },
  props: {
    file: {
      type: File,
      require: false
    },
    id: {
      type: String,
      require: false
    },
    type: {
      type: String,
      require: false,
      default: 'file'
    },
    placeholder: {
      type: String,
      require: false
    }
  },

  computed: {
    newFile: {
      get() {
        return this.file;
      },
      set(value) {
        this.$emit('setFile', value);
      }
    }
  }
};
</script>

<style>

</style>
